import React, { useEffect, useState } from "react";
//components
import { Container } from "../../components/container/container";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { NotificationModal } from "../../components/modals/notification/notification.modal";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/animations/perspective.css';
//utils
import moment from 'moment';
import 'moment/locale/pt-br';
import { LabelInput } from "../../components/label/label.input";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getClientEspecific, getLinkedOperatorToSupervisor, getPartnersHours, getPartnersLinkedForUser, getPartnersWithLinkedFromSupervisor, getScheduleAttachmentByName, getScheduleAttachments } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { ValidPeriodTime } from "../../utils/validators/hours.validator";
//contexts
import { useAuth } from "../../contexts/useAuth";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { hourTime } from "../../components/calendar/hourTime";
import { useThemeContext } from "../../contexts/themeContext";
//serivces
import { api } from "../../services/api/api";
import { maskHours } from "../../utils/mask/maskHours";
import { permittedFiles } from "../../utils/filesValidadtor/permittedFiles";
import { convertURLtoFile } from "../../utils/formatImages/urlToFile";
import { FaSave, FaTrash } from "react-icons/fa";
import { CalendarMain } from "../../components/calendar/calendar.main";
import { LogHistory } from "../../components/logHistory/logHistory";
import { listHours } from "../../utils/listHours";
import { validateFields } from "../../utils/form.validator";

export function OperatorScheduleEdit({ selectedEvent }) {

    const { userData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()

    const [loading, setLoading] = useState(true)
    const [showAnimate, setShowAnimate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [value, setValue] = useState(moment().locale('pt-br'))
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')

    const [searchPartners, setSearchPartners] = useState(false)
    const [clientList, setClientList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [partnersHours, setPartnersHours] = useState([])
    const [partnersFinishHours, setPartnersFinishHours] = useState([])
    const [addressStatePartnersList, setAddressStatePartnersList] = useState([])
    const [filteredAddressStatePartnersList, setFilteredAddressStatePartnersList] = useState([])
    const [addressCity, setAddressCities] = useState([])
    const [filteredAddressCity, setFilteredAddressCities] = useState([])
    const [completeHours, setCompleteHours] = useState([])
    const [formattedHours, setFormattedHours] = useState([])
    const [hoursEnd, setHoursEnd] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [confirmationList, setConfirmationList] = useState([])

    const [selectedClient, setSelectedClient] = useState([])
    const [selectedPartner, setSelectedPartner] = useState([])
    const [selectedAddressState, setSelectedAddressState] = useState([])
    const [selectedAddressCity, setSelectedAddressCity] = useState([])
    const [scheduleHoursFilter, setScheduleHoursFilter] = useState([])

    const [selectedDay, setSelectedDay] = useState('')
    const [selectedStartHour, setSelectedStartHour] = useState(0)
    const [selectedFinishHour, setSelectedFinishHour] = useState(0)
    const [selectedObservation, setSelectedObservation] = useState(``)
    const [selectedObservationPartner, setSelectedObservationPartner] = useState(``)
    const [selectedNewScheduleStatus, setSelectedNewScheduleStatus] = useState('')
    const [selectedReasonStatus, setSelectedReasonStatus] = useState(``)
    const [selectedStatusSchedule, setSelectedStatusSchedule] = useState(selectedEvent?.status ? selectedEvent?.status : '')
    const disabledFields = userData?.typeAccess_id == 3 && selectedEvent?.users_created !== userData?.id ? true : false
    const partnerDisabledFields = userData?.typeAccess_id !== 5 && userData?.typeAccess_id !== 6 ? true : false
    const [selectedTag, setSelectedTag] = useState('')
    const [selectedConfirmation, setSelectedConfirmation] = useState('')

    const [bodyData, setBodyData] = useState('')
    const [showLogs, setShowLogs] = useState(false)

    const [hoursCompleteList, setHoursCompleteList] = useState([])
    const [isAutoFinishHour, setIsAutoFinishHour] = useState(false)
    const [scheduleAttacments, setScheduleAttachments] = useState([])
    const [newScheduleAttacments, setNewScheduleAttachments] = useState([])
    const [attachmentToRemove, setAttachmentToRemove] = useState([])
    const statusList = [
        { description: 'Confirmado', id: 0 },
        { description: 'Não compareceu', id: 1 },
        { description: 'Pendente', id: 2 },
        { description: 'Contrato assinado', id: 3 },
        { description: 'Sem direito', id: 4 },
        { description: 'Desmarcado', id: 6 },
        { description: 'Sem interesse', id: 7 },
    ]
    const [blockData, setBlockData] = useState('')

    const [labels, setLabels] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const [selectedLabelDescription, setSelectedLabelDescription] = useState('')

    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {

        let attach = []
        let extension = originalName.slice(nameFile.indexOf(`.`), originalName?.length)
        let filename = `${originalName}${extension}`;
        filename = decodeURI(filename);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const file = await convertURLtoFile(url, filename)
        attach.push(
            {
                file,
                id
            }
        )
    }

    async function getData() {

        try {
            setLoading(true)

            const clientData = await getClientEspecific(userData.token, selectedEvent?.clients_id)
            let partnerListData = await getPartnersHours(userData.token)
            const scheduleAttach = await getScheduleAttachments(userData.token, selectedEvent.id)

            setScheduleAttachments(scheduleAttach.data)

            const getTagsAPI = await api.get('/api/v1/tags?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setTagsList(getTagsAPI?.data?.data?.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getConfirmationAPI = await api.get('/api/v1/confirmation?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getLabelsAPI = await api.get('/api/v1/labels?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setLabels(getLabelsAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `#${item?.description}`
                }
            }))

            const getScheduleLabels = await api.get(`/api/v1/scheduleLabels?schedule_id=${selectedEvent.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setSelectedLabels(getScheduleLabels?.data?.data || [])

            // Regra para trazer apenas parceiros e filiais do supervisor que estão vinculados
            if ([2].includes(userData.typeAccess_id)) {
                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {

                    let filteredPartnersList = []

                    partnerListData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })

                }
            }

            // REGRA PARA OPERADOR COM VINCULO NO SUPERVISOR, PARA PEGAR EMPRESA COM BASE NO SUPERVISOR
            if ([3].includes(userData.typeAccess_id)) {

                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)

                if (linkedSupervisorAPI.data.length > 0) {
                    let idsPartnersLink = []

                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnersToSupervisor.data.map(partner => {
                        idsPartnersLink.push(partner?.id)
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (idsPartnersLink.includes(partner?.id)) {
                            return partner
                        }
                    })
                }

            }

            setPartnerList(partnerListData.data)

            let stateArr = []
            let statesIncluded = []
            let citiesArr = []
            let citiesIncluded = []
            partnerListData.data.map(partner => {

                if (!statesIncluded.includes(partner.addressState)) {
                    stateArr.push({ addressState_externalId: partner.addressState_externalId, addressState: partner.addressState })
                    statesIncluded.push(partner.addressState)
                }
                if (!citiesIncluded.includes(partner.addressCity_externalId) && partner.addressState_externalId.slice(0, 2) === partner.addressState_externalId) {
                    citiesArr.push({ addressCity_externalId: partner.addressCity_externalId, addressCity: partner.addressCity })
                    citiesIncluded.push(partner.addressCity_externalId)
                }
            })
            setAddressStatePartnersList(stateArr.sort((a, b) => a.addressState_externalId - b.addressState_externalId))
            setFilteredAddressStatePartnersList(stateArr.sort((a, b) => a.addressState_externalId - b.addressState_externalId))
            setAddressCities(citiesArr.sort((a, b) => a.addressCity_externalId - b.addressCity_externalId))

            // Regra para trazer no campo "Cidade" apenas as cidades do estado do parceiro do agendamento selecionado para edição.
            let partnerUserSelectedEdit = partnerListData.data.filter(partner => partner.id === selectedEvent.partners_id)[0]
            let filteredCitiesForUser = citiesArr.filter(city => {
                let uc = city?.addressCity_externalId.slice(0, 2)
                if (uc === partnerUserSelectedEdit?.addressState_externalId) {
                    return city
                }
            })

            setFilteredAddressCities(filteredCitiesForUser.sort((a, b) => a.addressCity_externalId - b.addressCity_externalId))

            setSelectedClient(clientData?.data[0])
            setSelectedDay(moment(selectedEvent.scheduleDate).utc(false))
            setSelectedObservation(selectedEvent.observation)
            setSelectedTag(getTagsAPI?.data?.data?.find(item => item?.id == selectedEvent.tags_id))
            setSelectedConfirmation(getConfirmationAPI?.data?.data?.find(item => item?.id == selectedEvent.confirmation_id))
            setSelectedObservationPartner(selectedEvent.observationPartner)
            setSelectedReasonStatus(selectedEvent?.reasonStatus ? selectedEvent?.reasonStatus : '')
            let partner = partnerListData.data.filter(partner => partner.id === selectedEvent.partners_id)[0]
            setSelectedPartner(partner)
            setSelectedAddressCity(citiesArr.filter(city => city.addressCity_externalId === partner.addressCity_externalId)[0])
            setSelectedAddressState(stateArr.filter(state => state.addressState_externalId === partner.addressState_externalId)[0])
            setSelectedStartHour({ hour: selectedEvent.startHour })
            setSelectedFinishHour({ hour: selectedEvent.finishHour })



            const day = startDay.clone().subtract(1, 'day')
            const a = []
            while (day.isBefore(endDay, "day")) {
                a.push(
                    Array(7).fill(0).map(() => day.add(1, "day").clone())
                )
            }

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {

        function cleanState() {
            setSelectedAddressCity('')
            setSelectedAddressState('')
            setSelectedClient('')
            setSelectedObservation('')
            setSelectedStartHour('')
            setSelectedFinishHour('')
            setSelectedPartner('')
            setNewScheduleAttachments([])
        }

        cleanState()
    }, [showUniversalModal])

    async function getHoursForThisPartner(partner) {

        setHoursCompleteList(partner?.hours)
        let hoursToFilter = []
        partner.hours.filter((hoursOfPartner) => {
            if (parseInt(selectedDay.clone().format('d')) === hoursOfPartner.weekday) {
                hoursToFilter.push({
                    partner_id: partner.id,
                    startHour: hoursOfPartner.startHour,
                    finishHour: hoursOfPartner.finishHour
                })
            }
        })
        let hoursFinishPermitted = []
        let hoursStartPermitted = []

        hoursToFilter.filter((hour, index) => {
            completeHours.filter((hourToValid) => {
                if (hourToValid.hour <= hour.finishHour && hourToValid.hour >= hour.startHour) {
                    hoursStartPermitted.push({ ...hourToValid, id: index })
                }

                if (hourToValid.hour <= hour.finishHour && hourToValid.hour >= hour.startHour) {
                    hoursFinishPermitted.push({ ...hourToValid, maxFinishHour: hour.finishHour, id: index })
                }
            })
        })

        const getSchedulesOfPartner = await api.get(`/api/v1/schedule/partner/${partner?.id}/${moment(selectedDay).utc(false).format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        })

        if (getSchedulesOfPartner.data.data.length > 0) {
            const getHoursOfPartner = getSchedulesOfPartner.data.data?.filter(hours => hours?.id !== selectedEvent?.id)

            let filterFreeHours = structuredClone(hoursStartPermitted)
            for (const schedule of getHoursOfPartner) {
                const { startHour, finishHour } = schedule
                filterFreeHours = filterFreeHours.filter(hour => hour?.hour < startHour || hour?.hour >= finishHour)
            }

            hoursStartPermitted = hoursStartPermitted.map(hours => {
                const data = filterFreeHours?.filter(hours2 => hours2?.hour == hours?.hour)[0]
                if (data) {
                    return {
                        ...hours,
                        disabled: false
                    }
                }
                return {
                    ...hours,
                    disabled: true
                }
            })
            setScheduleHoursFilter(getHoursOfPartner)
        }
        setPartnersHours(hoursToFilter)
        if (hoursToFilter?.length > 0) {
            setPartnersFinishHours(hoursFinishPermitted)
            setFormattedHours(hoursStartPermitted)
        } else {
            setSelectedStartHour(``)
            setPartnersFinishHours([])
            setFormattedHours([])
        }
    }

    function handleFinishHour() {

        let filterHoursForFinish = partnersFinishHours.filter((hour) => {
            if ((hour?.hour > selectedStartHour?.hour && hour?.hour <= hour?.maxFinishHour && selectedStartHour?.id === hour?.id)) {
                return hour
            }
        })

        if (scheduleHoursFilter?.length > 0) {
            let filterFreeHours = structuredClone(filterHoursForFinish)
            for (const schedule of scheduleHoursFilter) {
                const { startHour, finishHour } = schedule
                filterFreeHours = filterFreeHours?.filter(hour => hour?.hour <= startHour || hour?.hour > finishHour)
            }

            filterHoursForFinish = filterHoursForFinish.map(hours => {
                const data = filterFreeHours?.filter(hours2 => hours2?.hour == hours?.hour)[0]
                if (data) {
                    return {
                        ...hours,
                        disabled: false
                    }
                }
                return {
                    ...hours,
                    disabled: true
                }
            })
        }

        setHoursEnd(filterHoursForFinish)
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'selectedTag', value: selectedTag, required: true, type: 'object' },
            { name: 'selectedConfirmation', value: selectedConfirmation, required: true, type: 'object' },
        ]

        if (([2,4,7].includes(selectedStatusSchedule) || [2,4,7].includes(selectedNewScheduleStatus?.id))) {
            requiredFields.push({ name: 'selectedReasonStatus', value: selectedReasonStatus, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)

        const validTime = new ValidPeriodTime(selectedStartHour, selectedFinishHour, hourTime)

        let newEvent = {
            status: 0,
            weekday: selectedDay.clone().format('d'),
            scheduleDate: selectedDay.clone().format("YYYY-MM-DD"),
            startHour: selectedStartHour?.hour,
            finishHour: selectedFinishHour.hour,
            observation: selectedObservation ? selectedObservation : undefined,
            partners_id: selectedPartner.id,
            clients_id: selectedClient.id,
            tags_id: selectedTag ? selectedTag?.id : undefined,
            confirmation_id: selectedConfirmation ? selectedConfirmation?.id : undefined
        }
        try {

            await api.put(`/api/v1/schedule/${selectedEvent.id}/status/${[0, 1, 2, 3, 4, 5, 6, 7].includes(selectedNewScheduleStatus?.id) ? selectedNewScheduleStatus?.id : selectedEvent?.status}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            if (selectedReasonStatus || selectedObservationPartner) {
                await api.put(`/api/v1/schedule/${selectedEvent.id}/observation`, {
                    observationPartner: selectedObservationPartner ? selectedObservationPartner : undefined,
                    reasonStatus: selectedReasonStatus ? selectedReasonStatus : undefined
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                })
            }

            const schedule = await api.put(`/api/v1/schedule/${selectedEvent.id}`,
                newEvent, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const scheduleId = schedule.data.data[0].id

            if (newScheduleAttacments?.length > 0) {
                await handleAttchamentFile(scheduleId)
            }

            if (attachmentToRemove?.length > 0) {
                await Promise.all(attachmentToRemove.map(async att => {
                    await handleDeleteAttachmentFiles(att)
                }))
            }

            try {
                if (selectedLabels?.length > 0){
                    await api.post('/api/v1/scheduleLabels', {
                        schedule_id: scheduleId,
                        labels: selectedLabels?.map(item => item?.id)
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                }
            } catch(error){
                console.log('Erro ao vincular etiquetas', error)
            }

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setReloadData(true)
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (error?.response?.data?.message) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(error?.response?.data?.message)
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function downloadAttachment(file) {

        const attachment = await getScheduleAttachmentByName(userData.token, selectedEvent.id, file.nameFile)
        await resolveAndDownloadBlob(attachment, file.nameFile, file.originalName, file.id)
        const fileToDownload = URL.createObjectURL(attachment.data)

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileToDownload;
        a.download = file.nameFile;
        a.click();
        window.URL.revokeObjectURL(fileToDownload);
    }

    function filterMesoregion(client) {
        const filtered = partnerList.filter(partner => {
            if (partner?.mesoregion_externalId === client?.mesoregion_externalId) {
                return partner
            } else {
                return partner
            }
        })

        if (filtered?.length === 0) {
            setSelectedAddressCity(``)
            setSelectedAddressState(``)
            // setSelectedPartner(``)
        }

        setFilteredPartners(filtered)
    }

    function filterPartnerByState(state) {

        setSelectedAddressState(state)
        let filteredPartnersState = []
        let filteredPartnersCities = []
        let citiesIncluded = []

        partnerList.filter(partner => {
            if (partner.addressState_externalId === state.addressState_externalId) {
                filteredPartnersState.push(partner)
            }
            addressCity.map(city => {
                if (!citiesIncluded.includes(city.addressCity_externalId) && (city.addressCity_externalId.slice(0, 2) === state.addressState_externalId)) {
                    filteredPartnersCities.push(city)
                    citiesIncluded.push(city.addressCity_externalId)
                }
            })
        })

        const city = filteredPartnersCities.filter(city => {
            if (filteredPartnersState[0].addressCity_externalId === city.addressCity_externalId) {
                return city
            }
        })

        city.push({ addressCity: `Todos`, addressCity_externalId: 0 })
        setFilteredPartners(filteredPartnersState)
        setSelectedPartner(filteredPartnersState[0])
        setSelectedAddressCity(city[1])
        setFilteredAddressCities(filteredPartnersCities)
    }

    function filterPartnerByCity(e) {

        if (e.addressCity == 'Todos' && e.addressCity_externalId == 0) {

            setSelectedAddressCity(e)
            let filteredPartnersState = []
            let filteredPartnersCities = []
            let citiesIncluded = []

            partnerList.filter(partner => {
                if (partner.addressState_externalId === selectedAddressState.addressState_externalId) {
                    filteredPartnersState.push(partner)
                }
                addressCity.map(city => {
                    if (!citiesIncluded.includes(city.addressCity_externalId) && (city.addressCity_externalId.slice(0, 2) === selectedAddressState.addressState_externalId)) {
                        filteredPartnersCities.push(city)
                        citiesIncluded.push(city.addressCity_externalId)
                    }
                })
            })
            const city = filteredPartnersCities.filter(city => {
                if (filteredPartnersState[0].addressCity_externalId === city.addressCity_externalId) {
                    return city
                }
            })

            setFilteredPartners(filteredPartnersState)
            setSelectedPartner(filteredPartnersState[0])
            // setSelectedAddressCity(city[0])
            setFilteredAddressCities(filteredPartnersCities)
        } else {
            const filteredPartners = partnerList.filter(partner => {
                if (partner.addressCity_externalId === e.addressCity_externalId) {
                    return partner
                }
            })

            setFilteredPartners(filteredPartners)
            setSelectedPartner(filteredPartners[0])
            setSelectedAddressCity(e)
        }

    }

    function filterCityByPartner(partner) {

        setSelectedPartner(partner)

        const city = addressCity.filter(city => city.addressCity_externalId === partner.addressCity_externalId)
        setSelectedAddressCity(city[0])
    }

    function selectCityAndStateByClient(client) {
        if (!selectedPartner) {
            partnerList.map(partner => {
                if (partner.addressState_externalId === client.addressState_externalId && partner.addressCity_externalId === client.addressCity_externalId) {
                    const state = addressStatePartnersList.filter(state => state.addressState_externalId === partner.addressState_externalId)[0]
                    const city = addressCity.filter(city => city.addressCity_externalId.slice(0, 2) === state.addressState_externalId)
                    setSelectedAddressState(state)
                    setSelectedAddressCity(city[0])
                    setFilteredAddressCities(city)
                    return setSelectedPartner(partner)
                } else if (partner.addressState_externalId === client.addressState_externalId) {
                    const state = addressStatePartnersList.filter(state => state.addressState_externalId === partner.addressState_externalId)[0]
                    const city = addressCity.filter(city => city.addressCity_externalId.slice(0, 2) === state.addressState_externalId)
                    setSelectedAddressState(state)
                    setFilteredAddressCities(city)
                    return setSelectedPartner(partner)
                }
            })
        }
    }

    function formatEventStatus() {

        switch (selectedEvent.status) {
            case 0:
                return 'Agendado'
            case 1:
                return 'Não compareceu'
            case 2:
                return 'Pendente'
            case 3:
                return 'Contrato assinado'
            case 4:
                return 'Sem direito'
            case 6:
                return 'Desmarcado'
            case 7:
                return 'Sem interesse'
            default:
                break;
        }
    }

    useEffect(() => {
        filterMesoregion(selectedClient)
        selectCityAndStateByClient(selectedClient)
        setSearchPartners(false)
        if (selectedPartner?.id > 0) {
            getHoursForThisPartner(selectedPartner)
        }
    }, [selectedClient])

    useEffect(() => {

        if (showUniversalModal) {
            getData()
        }

        let formattedHours = []
        hourTime.map(hour => {
            formattedHours.push({ hour: hour.hour, description: maskHours(hour) })
        })
        setFormattedHours(formattedHours)
        setCompleteHours(formattedHours)
        setHoursEnd(formattedHours)
    }, [selectedEvent, showUniversalModal])

    useEffect(() => {
        async function getDataAPI() {
            if (selectedPartner?.id) {
                await getBlockOnDateAndPartner(moment(selectedDay)?.utc(false).format('YYYY-MM-DD'), selectedPartner?.id)
                getHoursForThisPartner(selectedPartner)
            }
        }

        getDataAPI()

    }, [selectedPartner, selectedDay])

    useEffect(() => {

        handleFinishHour()

    }, [selectedStartHour])

    function addNewAttachment() {
        const inputFile = document.getElementById('fileInput')
        inputFile.click()
    }

    async function getBlockOnDateAndPartner(date, partner) {
        try {
            const getPartnersData = await api.get(`/api/v1/blockSchedule?dateOff=${date}&partners_id=${partner}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setBlockData(getPartnersData?.data?.data[0])
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const handleNewPhoto = (photoFile) => {

        const inputFile = document.getElementById('fileInput')

        const checkFile = permittedFiles(photoFile.target.files[0])
        if (checkFile.error) {
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(checkFile.response)
            return removeThisAttachment(photoFile)
        }

        if (newScheduleAttacments.length < 5) {
            setNewScheduleAttachments([photoFile.target.files[0], ...newScheduleAttacments])
        }
    }

    async function removeThisAttachment(attachment, indexAttchament) {
        setNewScheduleAttachments(newScheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function removeThisAttachmentHistory(attachment, indexAttchament) {
        setAttachmentToRemove([...attachmentToRemove, attachment])
        setScheduleAttachments(scheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function handleAttchamentFile(scheduleId) {

        try {

            const formData = new FormData();

            newScheduleAttacments.map((attachment) => {
                formData.append('files', attachment);
            })

            const config = {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    'content-type': 'multipart/form-data',
                },
            }
            await api.post(`/api/v1/scheduleAttachments/schedule_id/${scheduleId}?notify=true`, formData, config)
            return
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleDeleteAttachmentFiles(attach) {

        try {

            await api.delete(`/api/v1/scheduleAttachments/${attach.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            return

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {

                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteEvent() {

        setLoading(true)
        if (scheduleAttacments.length > 0) {
            await Promise.all(scheduleAttacments.map(async att => {
                await handleDeleteAttachmentFiles(att)
            }))
        }

        try {

            await api.delete(`/api/v1/schedule/${selectedEvent.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            setReloadData(true)
            return setShowNotificationModalText(`Agendamento descartado com sucesso`)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {

                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function changeScheduleStatus(e) {
        setSelectedNewScheduleStatus(e)
    }

    //funcao para tratar os intervalos de agendamentos definidos no cadastro do parceiro
    async function verifyTimeInterval(startHour) {
        if (selectedPartner?.time_interval) {
            let diff = startHour?.hour + (selectedPartner?.time_interval / 60)
            let autoFinishHour = formattedHours.filter(hour => { return hour?.hour == diff })[0]
            setSelectedFinishHour(autoFinishHour)
            setIsAutoFinishHour(true)

        } else {
            setIsAutoFinishHour(false)
        }
    }

    return (
        <Container>
            <NotificationModal dontShow={true} isAuth={true} />
            <div className={`${showDeleteModal ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                <div className="absolute z-[9999999992] p-6 bg-white dark:bg-primaryDefaultDarkColor shadow-lg rounded-lg flex flex-col items-center justify-center">
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                <a className="mb-6 text-base text-titleBlackTextLight dark:text-titleGrayTextDark">Descartar este evento?</a>
                                <div className="flex flex-row gap-2 items-center justify-center">
                                    <Button shadow={true} onClick={() => handleDeleteEvent()}>Sim</Button>
                                    <Button shadow={true} onClick={() => setShowDeleteModal(false)} approval={false}>Cancelar</Button>
                                </div>
                            </>
                    }
                </div>
                <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
            </div>
            <div className="flex flex-col bg-bgPrimaryLight dark:bg-primaryDefaultDark shadow-lg overflow-auto dark:scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-zinc-700 scrollbar-thumb-zinc-600">
                {
                    loading ?
                    <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px' }} className="flex flex-col items-center justify-center h-[30rem]">
                        <DefaultLoader />
                    </div>
                    :
                    <div style={{ width: screenX - 30 + 'px', maxWidth: `${screenX >= 1920 ? '1200px' : '900px'}`, maxHeight: screenY - 200 + 'px' }} className="px-6 flex flex-col w-full justify-start items-center gap-6 dark:scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-zinc-700 scrollbar-thumb-zinc-600">
                        <Tippy content={`Descartar`}
                            arrow={true}
                            animation='perspective'
                            placement='bottom'
                            zIndex={`999999999`}
                            delay={100}>
                        </Tippy>
                        <div className="flex flex-row fixed top-2 left-10 items-center justify-start gap-2">
                            <Button onClick={() => handleSubmit()}><FaSave /> Salvar</Button>
                            {
                                [1, 2].includes(userData?.typeAccess_id) ?
                                    <Tippy content={`Excluir evento`}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        zIndex={`999999999`}
                                        delay={100}>
                                        <div onClick={() => setShowDeleteModal(true)} className="cursor-pointer text-xl" >
                                            <FaTrash className="text-red-800" />
                                        </div>
                                    </Tippy>
                                    : <></>
                            }
                        </div>
                        <div className={`${screenX >= 1920 ? 'flex flex-row' : 'flex flex-col'} items-center justify-center gap-6 w-full`}>
                            <div className={`${screenX >= 1920 ? 'flex flex-col' : 'flex flex-row'} gap-4 items-center justify-around shadow-lg border border-gray-200 dark:border-secondaryBorderDark w-full`}>
                                <div className="flex p-3 flex-col rounded-lg lg:items-start items-center justify-start">
                                    <LabelInput text={'Cliente'}>
                                        <InputAutoComplete disabled={true} preSelectedValue={selectedClient.firstname} data={[]} onChange={(e) => { setSelectedClient(e) }} selectedLabel={`firstname`} optionList={[`id`, `firstname`]}></InputAutoComplete>
                                    </LabelInput>
                                    {
                                        filteredPartners?.length > 0 ?
                                            <>
                                                <LabelInput text={'Estado'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedAddressState?.addressState} data={addressStatePartnersList} onChange={(e) => { filterPartnerByState(e); setSelectedStartHour({ hour: '' }); setSelectedFinishHour({ hour: '' }) }} selectedLabel={`addressState`} optionList={[`addressState_externalId`, `addressState`]}></InputAutoComplete>
                                                </LabelInput>
                                                <LabelInput text={'Cidade'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedAddressCity?.addressCity} data={[{ addressCity: `Todos`, addressCity_externalId: 0 }, ...filteredAddressCity]} onChange={(e) => { filterPartnerByCity(e); setSelectedAddressCity(e) }} selectedLabel={`addressCity`} optionList={[`addressCity_externalId`, `addressCity`]}></InputAutoComplete>
                                                </LabelInput>
                                                <LabelInput text={'Parceiro'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedPartner?.name}
                                                        data={filteredPartners}
                                                        onChange={(e) => {
                                                            if (typeof (e) == 'object') {
                                                                filterCityByPartner(e)
                                                            }
                                                            setSelectedPartner(e); setSelectedStartHour({ hour: '' }); setSelectedFinishHour({ hour: '' })
                                                        }}
                                                        selectedLabel={`name`} optionList={[`id`, `name`]} />
                                                </LabelInput>

                                            </>
                                            :
                                            <div className="flex flex-col gap-4 dark:bg-secondaryDefaultDarkrounded-lg lg:items-start items-center justify-start h- w-8">
                                                <LabelInput text={'Estado'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedAddressState?.addressState} data={addressStatePartnersList} onChange={(e) => { filterPartnerByState(e); setSelectedStartHour({ hour: '' }); setSelectedFinishHour({ hour: '' }) }} selectedLabel={`addressState`} optionList={[`addressState_externalId`, `addressState`]}></InputAutoComplete>
                                                </LabelInput>
                                                <LabelInput text={'Cidade'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedAddressCity?.addressCity} data={[{ addressCity: `Todos`, addressCity_externalId: 0 }, ...filteredAddressCity]} onChange={(e) => { filterPartnerByCity(e); setSelectedAddressCity(e) }} selectedLabel={`addressCity`} optionList={[`addressCity_externalId`, `addressCity`]}></InputAutoComplete>
                                                </LabelInput>
                                                <LabelInput text={'Parceiro'}>
                                                    <InputAutoComplete disabled={disabledFields} preSelectedValue={selectedPartner?.name} data={filteredPartners} onChange={(e) => { setSelectedPartner(e); filterCityByPartner(e); setSelectedStartHour({ hour: '' }); setSelectedFinishHour({ hour: '' }) }} selectedLabel={`name`} optionList={[`id`, `name`]}></InputAutoComplete>
                                                </LabelInput>
                                            </div>
                                    }
                                </div>
                                <div className={`flex flex-col items-start justify-start p-2 ${screenX >= 1920 ? 'mr-0' : 'mr-40'}`}>
                                    <div id='scheduleCreateCalendar' className={`flex flex-col ${blockData ? 'gap-2' : 'gap-4'} rounded-lg items-start justify-start lg:w-64`}>
                                        <div className={`px-2 flex flex-col items-start justify-start ${blockData ? 'mb-4' : 'mb-6'}`}>
                                            <p className={`text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor ${blockData ? 'mb-0' : 'mb-3'}`}>
                                                Horário
                                            </p>
                                            {
                                                blockData &&
                                                <span className="text-sm text-red-800 mb-3">
                                                    Bloqueio: {`${listHours?.filter(hour => hour?.id == blockData?.startHour)[0]?.name} às ${listHours?.filter(hour => hour?.id == blockData?.finishHour)[0]?.name}`}
                                                </span>
                                            }
                                            <div className="flex lg:flex-row flex-col gap-2 items-start justify-start">
                                                {
                                                    formattedHours?.length == 0 ?
                                                        <LabelInput>
                                                            <a className="text-red-700 text-center">Parceiro não atende nesse dia da semana</a>
                                                        </LabelInput>
                                                        :
                                                        <>
                                                            <LabelInput text={'Inicio'}>
                                                                <InputAutoComplete preSelectedValue={selectedStartHour?.hour && maskHours(selectedStartHour)}
                                                                    value={selectedStartHour.description ? selectedStartHour.description : ''}
                                                                    width={28} onChange={(e) => { setSelectedStartHour(e); setSelectedFinishHour({ hour: '' }); verifyTimeInterval(e) }}
                                                                    data={formattedHours} selectedLabel={formattedHours.description} optionList={['description']} />
                                                            </LabelInput>
                                                            <LabelInput text={'Fim'}>
                                                                <InputAutoComplete preSelectedValue={selectedFinishHour?.hour && maskHours(selectedFinishHour)}
                                                                    disabled={selectedPartner?.time_interval ? true : hoursEnd.length == 0 ? true : false}
                                                                    value={selectedFinishHour?.description ? selectedFinishHour?.description : ''}
                                                                    width={28} onChange={(e) => setSelectedFinishHour(e)} data={hoursEnd} selectedLabel={formattedHours.description}
                                                                    optionList={['description']} />
                                                            </LabelInput>
                                                        </>
                                                }
                                            </div>
                                            <div className='dark:bg-secondaryDefaultDark bg-bgPrimaryLight lg:w-64 w-64 p-2 flex flex-col gap-4 mt-2'>
                                                <CalendarMain onClick={(e) => setSelectedDay(e)} externalSelectedDay={selectedDay} hours={selectedPartner ? hoursCompleteList : ''}></CalendarMain>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" p-2 flex flex-col items-center justify-center  shadow-inner">
                                <div id="scheduleCreateData" className="flex lg:flex-row flex-col gap-4 mt-9 lg:w-full lg:items-start lg:justify-between lg:h-72 " >
                                    <div className="flex flex-col items-center lg:items-start justify-center lg:w-96 rounded-lg gap-4 ">
                                        <div className=" border-b border-gray-200 dark:border-secondaryBorderDark w-full ">
                                            <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do cliente</p>
                                        </div>
                                        <div className="mt-1 w-full flex flex-col items-start justify-start mb-4">
                                            {
                                                selectedClient?.id > 0 &&
                                                <div className=" flex flex-col rounded-lg gap-4 w-full" >
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cliente:</strong>{` ${selectedClient.firstname}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{` ${selectedClient.addressCity}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{` ${selectedClient.addressState}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{` ${selectedClient.mesoregion_externalId}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>CPF:</strong>{` ${selectedClient?.cpf}`}</a>
                                                    {
                                                        selectedClient?.dddmobile && selectedClient?.mobile &&
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Celular:</strong>{` (${selectedClient?.dddmobile}) ${selectedClient?.mobile}`}</a>
                                                    }
                                                    {
                                                        selectedClient?.dddphone && selectedClient?.phone &&
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Telefone:</strong>{` (${selectedClient?.dddphone}) ${selectedClient?.phone}`}</a>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-start justify-start lg:w-96  gap-4">
                                        <div className="lg:w-full border-b bordar-gray-200 dark:border-secondaryBorderDark">
                                            <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do parceiro</p>
                                        </div>
                                        <div className="mt-1 lg:w-full flex flex-col items-start justify-start">
                                            {
                                                selectedPartner?.id > 0 &&
                                                <div className=" flex flex-col rounded-lg gap-4" >
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{` ${selectedPartner.name}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{` ${selectedPartner.addressCity}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{` ${selectedPartner.addressState}`}</a>
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{` ${selectedPartner.mesoregion_externalId}`}</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id='scheduleCreateObservation' className="flex flex-col p- gap-4 w-full rounded-lg xl:items-start items-start justify-start">
                                    <div className="flex flex-col gap-2 items-start justify-center w-full">
                                        <div className="border-b border-gray-200 dark:border-secondaryBorderDark w-full mt-5">
                                            <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Detalhes</p>
                                        </div>
                                        <div className="flex flex-row w-full">
                                            <div className="flex flex-col w-[50%] gap-2 items-start">
                                                <input
                                                    className="
                                                        h-8 pl-2 border border-gray-400 border-opacity-75 rounded-sm outline-none
                                                        text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow
                                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                        w-96                                                             
                                                    "
                                                    placeholder="Pesquisar etiqueta"
                                                    onChange={(e) => setSelectedLabelDescription(e?.target?.value)}
                                                    value={selectedLabelDescription || ''}
                                                />
                                                <div className="max-h-20 h-20 overflow-auto w-96 flex flex-wrap border border-gray-200 shadow-inner gap-2 p-2">
                                                    {
                                                        labels?.map(label => {
                                                            const find = selectedLabels?.find(item => item?.id == label?.id)
                                                            if (find) { return null }
                                                            return label
                                                        })
                                                        ?.filter(item => item != null)
                                                        ?.filter(item => !!selectedLabelDescription ? item?.description?.toLowerCase()?.includes(selectedLabelDescription?.toLowerCase()) : item)
                                                        ?.map(label => (
                                                            <p onClick={() => setSelectedLabels(prev => ([...prev, label]))} className="h-fit py-1 px-2 text-sm border border-gray-400 cursor-pointer hover:opacity-50 duration-100 transition-all">
                                                                #{String(label?.description)?.substring(0,12)}
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                                <div className="flex flex-wrap border border-gray-100 w-96 h-full max-h-28 overflow-auto rounded-sm gap-2 p-2">
                                                    {
                                                        selectedLabels?.length > 0 ?
                                                            selectedLabels?.map(label => (
                                                                <p 
                                                                    style={{ backgroundColor: label?.color || '#0B2E58' }} 
                                                                    className={`text-sm w-fit h-fit py-1 px-2 rounded-md text-white cursor-pointer hover:opacity-75 duration-100 transition-all`}
                                                                    onClick={() => setSelectedLabels([...selectedLabels?.filter(item => item?.id != label?.id)])}
                                                                >
                                                                    #{String(label?.description).substring(0,13)}
                                                                </p>
                                                            ))
                                                        :
                                                        <p className="text-gray-400 p-2">Etiquetas selecionadas</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-1 ml-5">
                                                <LabelInput text={'Tese *'}>
                                                    <InputAutoComplete 
                                                        id={'selectedTag'}
                                                        value={selectedTag ? selectedTag?.description : ''}
                                                        preSelectedValue={selectedTag ? selectedTag?.description : ''}
                                                        width={80} 
                                                        disabled={disabledFields} 
                                                        data={tagsList} 
                                                        selectedLabel={`description`} 
                                                        optionList={['inputLabel']} 
                                                        onChange={(e) => {setSelectedTag(e)}}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Confirmação *'}>
                                                    <InputAutoComplete 
                                                        id={'selectedConfirmation'}
                                                        value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                        preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                        width={80} 
                                                        data={confirmationList} 
                                                        disabled={disabledFields} 
                                                        selectedLabel={`description`} 
                                                        optionList={['inputLabel']} 
                                                        onChange={(e) => {setSelectedConfirmation(e)}}
                                                    />
                                                </LabelInput>
                                                <textarea 
                                                    disabled={disabledFields} 
                                                    placeholder={'Observação do usuário nobre'} 
                                                    value={selectedObservation} 
                                                    wrap="hard" 
                                                    className="w-full outline-none focus:shadow-borderShadow transition-all duration-300 lg:h-28
                                                                bg-white dark:bg-secondaryDefaultDark border border-gray-100
                                                                dark:border-secondaryBorderDark shadow-inner p-2 mt-3"
                                                    onChange={(e) => setSelectedObservation(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex lg:flex-row flex-col mt-2  gap-2 items-start justify-start lg:h-28 rounded-sm  w-full">
                                    <div onClick={() => addNewAttachment()} className="p-2 lg:mt-0  mt-2 hover:brightness-125  bg-primaryDefaultLight dark:bg-primaryDefaultDarkColor rounded-lg cursor-pointer h-full flex flex-col items-center justify-center w-40 whi">
                                        <p className=" text-titleGrayTextDark ">Anexos</p>
                                    </div>
                                    <input disabled={disabledFields} className="hidden" id='fileInput' type={'file'} onChange={(e) => handleNewPhoto(e)}></input>
                                    <div className="bg-white border dark:bg-secondaryDefaultDark border-gray-200 dark:border-secondaryBorderDark p-2 w-full h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap">
                                        {
                                            scheduleAttacments?.length > 0 &&
                                            scheduleAttacments.map(attachment => {
                                                return <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-white shadow-lg flex-col items-center justify-center relative">
                                                    {
                                                        !disabledFields ?
                                                            <span onClick={() => removeThisAttachmentHistory(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-zinc-700 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                            : <></>
                                                    }
                                                    <a onClick={() => downloadAttachment(attachment)}>{attachment?.originalName.slice(0, 10)?.concat(`...`)}</a>
                                                </div>
                                            })
                                        }
                                        {
                                            newScheduleAttacments?.length > 0 &&
                                            newScheduleAttacments.map(attachment => {
                                                return <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-primaryDefaultLight text-titleGrayTextDark shadow-lg flex-col items-center justify-center relative">
                                                    <span onClick={() => removeThisAttachment(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-zinc-700 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                    <a href={URL.createObjectURL(attachment)} download>{attachment?.name?.slice(0, 8).concat('...').concat(attachment.name.slice(attachment.name.length - 3, attachment.name.length))}</a>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex lg:flex-col flex-col gap-4 items-start justify-start lg:w-full h-96">
                            <div className="p-2 flex flex-row items-start justify-start lg:w-full border-b border-gray-200 dark:border-secondaryDefaultDark">
                                <p className="text-base w-full text-primaryDefaultLight dark:text-primaryDefaultDarkColor mr-5">Área do parceiro</p>
                            </div>
                            <div className="flex lg:flex-row flex-col gap-6 lg:m-0 m-auto">
                                <div className="flex flex-col gap-4 p-2 items-start justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark w-[350px]">
                                    <div className="flex flex-col gap-2 items-start justify-center">
                                        <div className="flex flex-row gap-2 b">
                                            {
                                                //variavel para saber se é parceiro
                                                1 === 1 ?
                                                    <LabelInput text={'Status'}>
                                                        <InputAutoComplete
                                                            width={64}
                                                            name='selectedNewScheduleStatus'
                                                            preSelectedValue={
                                                                selectedEvent?.status ? statusList.filter(status =>
                                                                    status?.id == selectedEvent?.status
                                                                )[0]?.description : statusList[0]?.description
                                                            }
                                                            data={userData?.typeAccess_id == 3 ? [{ description: 'Desmarcado', id: 6 }] : statusList}
                                                            optionList={['description']}
                                                            selectedLabel={'description'}
                                                            onChange={(e) => { setSelectedNewScheduleStatus(e); setSelectedStatusSchedule('') }}
                                                        />
                                                    </LabelInput>
                                                    :
                                                    <>
                                                        <strong><p>Status:</p></strong>
                                                        <a>{formatEventStatus()}</a>
                                                    </>
                                            }
                                        </div>
                                        <div className="lg:h-64">
                                            {
                                                [2,4,7].includes(selectedStatusSchedule) || [2,4,7].includes(selectedNewScheduleStatus?.id) ?
                                                    <LabelInput text={'Motivo'}>
                                                        <textarea
                                                            disabled={disabledFields}
                                                            wrap="hard"
                                                            className="text-sm w-64 outline-none focus:shadow-borderShadow transition-all duration-300 h-40 bg-zinc-50 border border-zinc-100 dark:border-secondaryBorderDark shadow-inner p-2 dark:bg-secondaryDefaultDark"
                                                            value={selectedReasonStatus ? selectedReasonStatus : ''}
                                                            id='selectedReasonStatus'
                                                            onChange={(e) => setSelectedReasonStatus(e.target.value)}
                                                        />
                                                    </LabelInput>
                                                    : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[28rem]">
                                    <textarea
                                        disabled={partnerDisabledFields}
                                        value={selectedObservationPartner ? selectedObservationPartner : ''}
                                        placeholder='Observação do parceiro'
                                        wrap="hard"
                                        className="lg:w-full lg:h-full resize-none text-sm outline-none dark:text-titleGrayTextDark focus:shadow-borderShadow transition-all duration-300 bg-zinc-50 border border-zinc-100 dark:border-secondaryBorderDark shadow-inner dark:bg-secondaryDefaultDark p-2"
                                        onChange={(e) => setSelectedObservationPartner(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="p-2 flex flex-col items-start justify-start lg:w-full border-b borer-gray-200">
                            </div>
                            <div className="p-2 flex flex-col items-start justify-start lg:w-full border-b border-gray-200 dark:border-secondaryDefaultDark">
                                {
                                    [1, 2, 3].includes(userData.typeAccess_id) &&
                                    <div className="w-full rounded-lg">
                                        {
                                            !showLogs ?
                                            <div className="flex w-full items-center justify-center pb-52">
                                                <Button onClick={() => setShowLogs(true)}>Mostrar Histórico</Button>
                                            </div>
                                            : bodyData ?
                                            <div className="flex flex-col justify-between items-center gap-2">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Detalhes do Log</p>
                                                <br />
                                                <pre>{JSON.stringify(JSON.parse(bodyData), null, 2)}</pre>
                                                <br />
                                                <Button approval={false} onClick={() => setBodyData()}>Voltar</Button>
                                            </div>
                                            :
                                            <LogHistory
                                                cancelModal={true}
                                                cancelTippy={true}
                                                bodyData={(e) => {
                                                    setBodyData(e)
                                                }}
                                                apiData={{
                                                    params: `?table_name=schedule&table_id=${selectedEvent?.id}`
                                                }}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Container>
    )

}